import "./portlist.scss";

const Portlist = ({ title, active, setChoice, id }) => {
  return (
    <li
      className={active ? "portlist active" : "portlist"}
      onClick={() => setChoice(id)}
    >
      {title}
    </li>
  );
};

export default Portlist;

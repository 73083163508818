import Portlist from "../portlist/Portlist";
import "./portfolio.scss";
import { useEffect, useState } from "react";
import {
  featuredPortfolio,
  webPortfolio,
  designPortfolio,
  blogPortfolio,
} from "../../data";

export const Portfolio = () => {
  const [choice, setChoice] = useState("featured");
  const [data, setData] = useState([]);
  const list = [
    { id: "featured", title: "Featured" },
    { id: "websites", title: "Websites" },
    // { id: "projects", title: "Projects" },
    // { id: "blog", title: "Blog" },
  ];

  useEffect(() => {
    switch (choice) {
      case "featured":
        setData(featuredPortfolio);
        break;
      case "websites":
        setData(webPortfolio);
        break;
      case "blogs":
        setData(blogPortfolio);
        break;
      case "projects":
        setData(designPortfolio);
        break;
      default:
        setData(featuredPortfolio);
    }
  }, [choice]);

  return (
    <div className="portfolio" id="portfolio">
      <h1>Portfolio</h1>
      <ul>
        {list.map((item) => (
          <Portlist
            key={item.id}
            title={item.title}
            active={choice === item.id}
            setChoice={setChoice}
            id={item.id}
          />
        ))}
      </ul>
      <div className="container">
        {data.map((d) => (
          <a href={d.link} target={d.target} rel="noreferrer">
            <div className="item" key={d.id}>
              <img src={d.img} alt="" />
              <h3>{d.title}</h3>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};

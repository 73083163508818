import "./navbar.scss";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import MailIcon from "@mui/icons-material/Mail";
import GitHubIcon from "@mui/icons-material/GitHub";
export const Navbar = ({ menuOpen, setMenuOpen }) => {
  return (
    <div className={"navbar " + (menuOpen && "active")}>
      <div className="wrapper">
        <div className="left">
          <div id="logo">
            <a href="#intro" className="logo">
              <p className="name">moiz zhara</p>
            </a>
          </div>
          <div className="socialContainer">
            <a
              href="https://github.com/moiz-z"
              target={"_blank"}
              rel="noreferrer"
            >
              <GitHubIcon className="icon" />
            </a>
          </div>
          <div className="socialContainer">
            <a
              href="https://linkedin.com/in/moizzhara/"
              target={"_blank"}
              rel="noreferrer"
            >
              <LinkedInIcon className="icon" />
            </a>
          </div>
          <div className="socialContainer">
            <a href="mailto:moiz@moizz.dev">
              <MailIcon className="icon" />
            </a>
          </div>
        </div>
        <div className="right">
          <div className="hamburger" onClick={() => setMenuOpen(!menuOpen)}>
            <span className="line1"></span>
            <span className="line2"></span>
            <span className="line3"></span>
          </div>
        </div>
      </div>
    </div>
  );
};

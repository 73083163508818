export const featuredPortfolio = [
  {
    id: 1,
    title: "zVAPEz - Vape Shop",
    img: "/assets/zvapez.png",
    link: "https://www.zvapez.com",
  },
  {
    id: 2,
    title: "USDC Rates",
    img: "/assets/usdcrates.png",
    link: "https://www.usdcrates.com",
  },
];

export const webPortfolio = [
  {
    id: 1,
    title: "moizz.dev",
    img: "/assets/moizz.png",
    link: "#intro",
    target: "",
  },
  {
    id: 2,
    title: "zVAPEz - Vape Shop",
    img: "/assets/zvapez.png",
    link: "https://www.zvapez.com",
    target: "_blank",
  },
  {
    id: 3,
    title: "USDC Rates",
    img: "/assets/usdcrates.png",
    link: "https://www.usdcrates.com",
    target: "_blank",
  },
  {
    id: 4,
    title: "Quotes",
    img: "/assets/quotivation.png",
    link: "https://quotivation.herokuapp.com/",
    target: "_blank",
  },
  {
    id: 5,
    title: "Zippy Used Autos",
    img: "/assets/zippy.png",
    link: "https://zippyautos.herokuapp.com/",
    target: "_blank",
  },
];

export const blogPortfolio = [];

export const designPortfolio = [];

export const contentPortfolio = [];

import "./sites.scss";

import { useState } from "react";

export const Sites = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const data = [
    {
      id: "1",
      icon: "./assets/globe.png",
      title: "moizz.dev",
      link: "#intro",
      linktitle: "Back to the top",
      desc: "This website is made using HTML, CSS (Sass), and ReactJS to showcase my projects and anything else I find interesting. I will probably add a blog at some point (depends on whether or not I am really enthusiastic about writing)",
      img: "/assets/moizz.png",
      target: "",
    },
    {
      id: "2",
      icon: "./assets/globe.png",
      title: "zVAPEz - Vape Shop",
      link: "https://www.zvapez.com",
      linktitle: "Visit zVAPEz",
      desc: "zVAPEz is a site I made for a vape shop using HTML, CSS (Sass), and ReactJS. It was my first time using a large media file for a landing page. Working on this site helped me refine my skills of the various hooks that React has and learn best practices.",
      img: "/assets/zvapez.png",
      target: "_blank",
    },
    {
      id: "3",
      icon: "./assets/globe.png",
      title: "USDC Rates - Best APYs for USDC",
      link: "https://www.usdcrates.com",
      linktitle: "Visit USDC Rates",
      desc: "USDC Rates is made using ReactJS to provide affiliate sign up links to various crypto platforms and show the interests each platform offers. I plan to add more to the site as time goes by to further enhance my knowledge of React.",
      img: "/assets/usdcrates.png",
      target: "_blank",
    },
    {
      id: "4",
      icon: "./assets/globe.png",
      title: "Quotivation",
      link: "https://quotivation.herokuapp.com/",
      linktitle: "Visit Quotivation",
      desc: "Quotivation was one of the first sites I built using PHP and MySQL (XAMPP) when I was studying Computer Science at FHSU. Coding both the back and front end was fun and ultimately satisfying to see a snappy, mobile responsive site as result.",
      img: "/assets/quotivation.png",
      target: "_blank",
    },
  ];

  const handleClick = (way) => {
    way === "left"
      ? setCurrentSlide(currentSlide > 0 ? currentSlide - 1 : 2)
      : setCurrentSlide(currentSlide < data.length - 1 ? currentSlide + 1 : 0);
  };

  return (
    <div className="works" id="works">
      <div
        className="slider"
        style={{ transform: `translateX(-${currentSlide * 100}vw)` }}
      >
        {data.map((d) => (
          <div className="container">
            <div className="item">
              <div className="left">
                <div className="leftContainer">
                  <div className="imgContainer">
                    <img src={d.icon} alt="" />
                  </div>
                  <h2>{d.title}</h2>
                  <p>{d.desc}</p>
                  <a href={d.link} rel="noreferrer" target={d.target}>
                    <span>{d.linktitle}</span>
                  </a>
                </div>
              </div>
              <div className="right">
                <img src={d.img} alt="" />
              </div>
            </div>
          </div>
        ))}
      </div>
      <img
        src="assets/arrow.png"
        className="arrow left"
        alt=""
        onClick={() => handleClick("left")}
      />
      <img
        src="assets/arrow.png"
        className="arrow right"
        alt=""
        onClick={() => handleClick()}
      />
    </div>
  );
};

import "./testimonials.scss";

export const Testimonials = () => {
  const data = [
    {
      id: 1,
      name: "Chris Belsole",
      title: "Software Engineer at HomeLight",
      img: "/assets/chris.jpeg",
      icon: "assets/linkedin.png",
      desc: " I've worked with Moiz on a number of occasions and he's always shown an aptitude for quickly understanding complex topics and is immediately able to apply those concepts in new and interesting ways.",
      link: "#intro",
    },
    {
      id: 2,
      name: "Dave Gray",
      title:
        "Web Development Professor at Fort Hays State University, and YouTuber",
      img: "/assets/dave.jpeg",
      icon: "assets/youtube.png",
      desc: "Moiz was an excellent student in my university web development courses. His curiosity would drive him to apply the concepts we covered in new ways and to follow up with thoughtful questions. In addition, he helped answer his fellow classmates questions in the discussion forum every week.",
      link: "https://www.youtube.com/c/DaveGrayTeachesCode/",
      featured: true,
    },
    {
      id: 3,
      name: "Katelyn Stone",
      title: "Team Lead - Growth Account Management at Spotify",
      img: "/assets/kate.jpeg",
      icon: "assets/spotify.png",
      desc: "It has been an absolute pleasure to work with Moiz. He is incredibly knowledgeable and thrives in a collaborative setting. Moiz is passionate about assignments and works efficiently to get tasks completed. I look forward to working with Moiz on future projects.",
    },
  ];
  return (
    <div className="testimonials" id="testimonials">
      <h1>Testimonials</h1>
      <div className="container">
        {data.map((p) => (
          <div className={p.featured ? "card featured" : "card"}>
            <a href={p.link}>
              <div className="top">
                <img src="assets/right-arrow.png" className="left" alt="" />
                <img className="user" src={p.img} alt="" />
                <img className="right" src={p.icon} alt="" />
              </div>
            </a>
            <div className="middle">{p.desc}</div>
            <div className="bottom">
              <h3>
                <a href={p.link}>{p.name}</a>
              </h3>
              <h4>{p.title}</h4>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

import "./intro.scss";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { init } from "ityped";
import { useEffect, useRef } from "react";

export const Intro = () => {
  const textRef = useRef();
  useEffect(() => {
    init(textRef.current, {
      showCursor: true,
      backDelay: 1500,
      backSpeed: 60,

      strings: [
        "a web developer",
        "an MBA student",
        "an investor",
        "a product manager",
        "a credit analyst",
        "a gamer",
        "an entrepreneur",
        "a candle maker",
      ],
    });
  }, []);
  return (
    <div className="intro" id="intro">
      <div className="left">
        <div className="imgContainer">
          <img
            src="assets/casual5.webp"
            alt="casual headshot"
            className="headshot"
          />
        </div>{" "}
      </div>
      <div className="right">
        {" "}
        <div className="wrapper">
          <h2>Hello! My name is</h2>
          <h1> Moiz</h1>
          <h3>
            I'm <span>~$ </span>
            <span ref={textRef}></span>
          </h3>
        </div>
        <a href="#portfolio">
          <KeyboardDoubleArrowDownIcon className="arrow" />
        </a>
      </div>
    </div>
  );
};

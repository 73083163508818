import "./menu.scss";

export const Menu = ({ menuOpen, setMenuOpen }) => {
  return (
    <div className="screen" onClick={() => setMenuOpen(false)}>
      <div className={"menu " + (menuOpen && "active")}>
        <ul>
          <li onClick={() => setMenuOpen(false)}>
            <a href="#intro">Intro</a>
          </li>
          <li onClick={() => setMenuOpen(false)}>
            <a href="#portfolio">Portfolio</a>
          </li>
          <li onClick={() => setMenuOpen(false)}>
            <a href="#works">Sites</a>
          </li>
          <li onClick={() => setMenuOpen(false)}>
            <a href="#testimonials">Testimonials</a>
          </li>
          <li onClick={() => setMenuOpen(false)}>
            <a href="mailto:moiz@moizz.dev">Contact Me</a>
          </li>
        </ul>
      </div>
    </div>
  );
};
